<template>
  <router-view />
  <van-overlay :show="show">
    <div class="wrapper" @click.stop>
      <van-loading type="circular" color="#FFEB3B" />
    </div>
  </van-overlay>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "index",
  setup() {
    let show = ref(true);
    window.onload = function(){
      console.log("加载");
      show.value = false;
    }
    return { show };
  },
};

</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}
body,
html {
  background: #f1f2f2;
}
.wrapper{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
